import { GET_ALL_TAXONOMY_SLUGS } from '@/graphql/queries/taxonomy-queries';
import cache from '@/utils/lru-cache';

const CollectionComponent = () => import('@/pages/Blog/CollectionComponent.vue');
const RecipesIndex = () => import('@/pages/Recipes/RecipesIndex.vue');
const PostComponent = () => import('@/pages/Blog/PostComponent.vue');

/**
 * Function to convert a given taxon to a vue-router route definition
 * This may need to be extended in the future to route slugs to different
 * components, e.g. CAS collections?
 * @param {Object} taxon
 */
function collectionRoute(taxon) {
  return {
    path: `/${taxon.slug}`,
    name: `${taxon.slug}Collection`,
    component: CollectionComponent,
    meta: {
      title: taxon.name,
      tag: taxon.slug,
    },
  };
}

function recipeRoute(taxon) {
  return {
    path: `/tag/${taxon.slug}`,
    name: `${taxon.slug}Recipes`,
    component: RecipesIndex,
    meta: {
      title: taxon.name,
      tag: taxon.slug,
    },
  };
}

function removeDuplicates(array, router) {
  return array.filter((element, index) => {
    if (!element) return false;

    // Do not create dynamic route if a route is already defined
    if (router.match(element.path).matched.length > 0) {
      return false;
    }
    const thisElement = JSON.stringify(element);
    return index === array.findIndex((obj) => JSON.stringify(obj) === thisElement);
  });
}

function processRoutes(res, router) {
  const tmp = res.data.categories.map((taxon) => {
    if (taxon.indexType === 'recipes') {
      return recipeRoute(taxon);
    }

    if (taxon.hasPost) return false;

    return collectionRoute(taxon);
  });
  return removeDuplicates(tmp, router);
}

const wildcardRoutes = [
  {
    path: '/:slug',
    name: 'post',
    component: PostComponent,
  },
  {
    path: '/:category/:slug',
    name: 'categoryPost',
    component: PostComponent,
  },
  {
    path: '/tag/:slug',
    name: 'tag',
    component: CollectionComponent,
  },
  // This routing pattern is only used to handle 'paginated' infographic posts
  // Ideally that legacy content can be reformatted so that this can be removed
  // example: https://type2diabetes.local.healthunion.io/infographic/seven-steps-to-kick-start-weight-loss/4/
  {
    path: '*/:category/:slug/:pageNumber(\\d+)',
    name: 'globCategoryPostWithPageNumber',
    component: PostComponent,
  },
  {
    path: '*/:category/:slug',
    name: 'globCategoryPost',
    component: PostComponent,
  },
];

export const dynamicRoutes = (router, apolloProvider, siteId, store) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-underscore-dangle
  if (process.browser && window.__INITIAL_STATE__?.dynamicRoutes?.taxonamyQueryResult?.data) {
    // eslint-disable-next-line no-underscore-dangle
    const routes = processRoutes(window.__INITIAL_STATE__.dynamicRoutes.taxonamyQueryResult, router).concat(wildcardRoutes);
    router.addRoutes(routes);
    resolve();
    return;
  }

  const hit = cache.get(`${siteId}-taxonomy-query-result`);
  if (hit) {
    // Storing the cached query result in vuex allows us to skip this query on the front end.
    if (store) store.dispatch('setTaxonamyQueryResult', hit);
    const routes = processRoutes(hit, router).concat(wildcardRoutes);
    router.addRoutes(routes);
    resolve();
    return;
  }

  apolloProvider.defaultClient.query({
    query: GET_ALL_TAXONOMY_SLUGS,
  }).then((res) => {
    // Storing the query result in both vuex and the lru-cache allows us to skip
    // this query on the front end and for new requests in the same session.
    if (store) store.dispatch('setTaxonamyQueryResult', res);
    if (res?.data?.categories) {
      cache.set(`${siteId}-taxonomy-query-result`, res);
    }

    const routes = processRoutes(res, router).concat(wildcardRoutes);
    router.addRoutes(routes);
    resolve();
  }).catch((err) => {
    router.app.$logger.error(err);
    router.addRoutes(wildcardRoutes);
    reject(err);
  });
});
