import Vue from 'vue';
import Router from 'vue-router';
import { SITE_REDIRECTS_QUERY } from '@/graphql/queries/site-queries';
import { loggerApi } from '@/plugins/logger';
import cache from '@/utils/lru-cache';

import blogRoutes from '@/router/blog';
import forumRoutes from '@/router/forum';
import storiesRoutes from '@/router/stories';
import recipesRoutes from '@/router/recipes';
import memberRoutes from '@/router/member';
import authRoutes from '@/router/auth';
import userRoutes from '@/router/user';
import quizRoutes from '@/router/quiz';

const HomeComponent = () => import('@/pages/HomeComponent.vue');
const PostComponent = () => import('@/pages/Blog/PostComponent.vue');
const SearchPage = () => import('@/pages/SearchPage.vue');
const CommunityHub = () => import('@/pages/CommunityHub.vue');
const UnsubscribePage = () => import('@/pages/UnsubscribePage.vue');
const NotFoundPage = () => import('@/components/Global/NotFoundPage.vue');
const ConditionPage = () => import('@/pages/Conditions/ConditionPage.vue');
const DebugTool = () => import('@/pages/DebugTool.vue');
const ExperimentDemo = () => import('@/pages/ExperimentDemo.vue');

Vue.use(Router);

function routePreview(to) {
  if (['story', 'recipe', 'gallery'].includes(to.query.post_type)) {
    return {
      name: to.query.post_type,
      params: { id: to.query.p, slug: to.query.p },
      query: { preview: 'true', preview_id: to.query.p },
    };
  }

  return {
    name: 'postById',
    params: {
      id: (to.query.p || to.query.preview_id || to.query.page_id),
    },
    query: {
      // Leave preview undefined because preview=false can cause posts linked by ID to 404.
      preview: to.query.preview || to.query.preview_id || to.query.page_id ? 'true' : undefined,
    },
  };
}

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    beforeEnter(to, from, next) {
      const queryRoutes = ['s', 'p', 'preview_id', 'page_id'];
      const queryKeys = Object.keys(to.query);
      const rewriteQuery = queryKeys.some((k) => queryRoutes.includes(k));

      if (rewriteQuery) {
        if (queryKeys.includes('s')) {
          next({ name: 'search', query: to.query });
        } else {
          next(routePreview(to));
        }
      } else {
        next();
      }
    },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
  },
  {
    path: '/post/:id(\\d+)',
    name: 'postById',
    component: PostComponent,
  },
  {
    path: '/post/:slug',
    name: 'postBySlug',
    component: PostComponent,
  },
  {
    path: '/images/:slug',
    name: 'gallery',
    component: PostComponent,
    meta: { types: ['gallery'] },
  },
  {
    path: '/community',
    name: 'community',
    component: CommunityHub,
  },
  {
    path: '/homepage/:slug',
    name: 'homepagePreview',
    component: HomeComponent,
  },
  {
    path: '/email-unsubscribe',
    name: 'emailUnsubscribe',
    component: UnsubscribePage,
  },
  {
    path: '/unsubscribe.php',
    name: 'legacyUnsubscribe',
    component: UnsubscribePage,
  },
  {
    path: '/404',
    name: 'notFoundPage',
    component: NotFoundPage,
  },
  {
    path: '/conditions/:slug',
    name: 'conditionPage',
    component: ConditionPage,
  },
  {
    path: '/conditions/:conditionSlug/:slug',
    name: 'conditionPost',
    component: PostComponent,
  },
  {
    path: '/super-secret-debug-tool',
    name: 'debugTool',
    component: DebugTool,
  },
  {
    path: '/experiment-demo',
    name: 'experimentDemo',
    component: ExperimentDemo,
  },
  {
    // route that does nothing for light weight cache busting
    path: '/ok',
    name: 'ok',
    component: { template: '<div>ok</div>' },
  },
];

const routes = baseRoutes.concat(
  blogRoutes,
  forumRoutes,
  storiesRoutes,
  recipesRoutes,
  memberRoutes,
  authRoutes,
  userRoutes,
  quizRoutes,
);

async function getSiteRedirects(context, apolloProvider) {
  // NOTE: This query runs in the client but is resolved by apollo cache
  if (!process.browser) {
    const hit = cache.get(`yoastRedirects-${context.req.headers.host}`);
    if (hit) {
      return new Promise((resolve) => resolve(hit));
    }
  }

  return apolloProvider.defaultClient.query({
    query: SITE_REDIRECTS_QUERY,
  });
}

function initRouter(yoastRedirects) {
  const allRoutes = yoastRedirects.concat(routes);

  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: allRoutes,
    scrollBehavior(to, _from, savedPosition) {
      if (to.hash) {
        const heightOfHeader = 55;
        return new Promise((resolve) => {
          window.setTimeout(() => {
            resolve({ selector: to.hash, offset: { x: 0, y: heightOfHeader } });
          }, 1000);
        });
      } if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });
}

export async function createRouter(context, apolloProvider) {
  const logger = loggerApi({ context });
  let yoastRedirects = [];
  await getSiteRedirects(context, apolloProvider)
    .then((res) => {
      if (!res) { return; }
      if (!process.browser && !res.fromCache && res?.data?.site) {
        cache.set(`yoastRedirects-${context.req.headers.host}`, { ...res, fromCache: true });
      }
      yoastRedirects = res.data.site.yoastRedirects.map((redir) => (
        { path: redir.path, redirect: redir.redirect }
      ));
    }).catch((err) => {
      logger.error(err);
    });
  const router = initRouter(yoastRedirects);

  return router;
}
