/* eslint-disable no-underscore-dangle */
import { GET_SITE_SUMMARIES } from '@/graphql/queries/site-queries';
import { loggerApi } from '@/plugins/logger';
import cache from '@/utils/lru-cache';

async function getSiteList(app) {
  const hit = cache.get(`site-list-${app.context.req?.headers?.host}`);
  if (hit) {
    return new Promise((resolve) => resolve(hit));
  }

  return app.apolloProvider.defaultClient.query({
    query: GET_SITE_SUMMARIES,
  });
}

export default (app, inject) => new Promise((resolve, reject) => {
  const logger = loggerApi({ context: app.context });
  if (process.browser && window.__INITIAL_STATE__?.siteList) {
    const { siteList } = window.__INITIAL_STATE__;

    inject(app, '$siteList', siteList);
    resolve();
  } else {
    getSiteList(app)
      .then((result) => {
        if (!result.fromCache && result?.data?.siteSummaries) {
          cache.set(`site-list-${app.context.req?.headers?.host}`, { ...result, fromCache: true });
        }
        const siteList = result.data.siteSummaries;

        app.store.dispatch('formatSiteListArrayAndSetSites', siteList);
        resolve();
      }).catch((error) => {
        reject(error);
        logger.error(error);
      });
  }
});
