// A list of site urls to be used for crossDomainTracking(tracking.js)
export const siteDomains = [
  'AdvancedBreastCancer.net',
  'AdvancedOvarianCancer.net',
  'Allergies.net',
  'AlzheimersDisease.net',
  'Asthma.net',
  'AtopicDermatitis.net',
  'AxialSpondyloarthritis.net',
  'BladderCancer.net',
  'Blood-Cancer.com',
  'CardiovascularDisease.net',
  'ChronicDryEye.net',
  'Chronic-Hives.com',
  'Chronic-Kidney-Disease.net',
  'COPD.net',
  'Cystic-Fibrosis.com',
  'Endometriosis.net',
  'EpilepsyDisease.com',
  'H-I-V.net',
  'Heart-Failure.net',
  'HepatitisC.net',
  'HSDisease.com',
  'InflammatoryBowelDisease.net',
  'Insomnia.Sleep-Disorders.net',
  'IrritableBowelSyndrome.net',
  'LungCancer.net',
  'Lupus.net',
  'MacularDegeneration.net',
  'Mental-Health-Community.com',
  'Migraine.com',
  'MultipleSclerosis.net',
  'Myasthenia-Gravis.com',
  'Narcolepsy.Sleep-Disorders.net',
  'NASHDisease.net',
  'Neuromyelitis-Optica.net',
  'Obesity.net',
  'Opioid-Use-Disorder.com',
  'ParkinsonsDisease.net',
  'PlaquePsoriasis.com',
  'ProstateCancer.net',
  'Psoriatic-Arthritis.com',
  'RareDisease.net',
  'RestlessLegsSyndrome.Sleep-Disorders.net',
  'RheumatoidArthritis.net',
  'Sickle-Cell.com',
  'SkinCancer.net',
  'Sleep-Disorders.net',
  'SleepApnea.Sleep-Disorders.net',
  'SocialHealthNetwork.com',
  'SpinalMuscularAtrophy.net',
  'ThyroidEyeDisease.net',
  'Type2Diabetes.com',
];
//  TODO: possibly find a better way to maintain this list
// currently this will need to be added to once we launch new sites.
// This is a list of domains to be shown in the footer in AllSiteLinks.vue
export const footerDomains = [
  'AdvancedBreastCancer.net',
  'AdvancedOvarianCancer.net',
  'Allergies.net',
  'AlzheimersDisease.net',
  'Asthma.net',
  'AtopicDermatitis.net',
  'AxialSpondyloarthritis.net',
  'BladderCancer.net',
  'Blood-Cancer.com',
  'CardiovascularDisease.net',
  'ChronicDryEye.net',
  'Chronic-Hives.com',
  'Chronic-Kidney-Disease.net',
  'COPD.net',
  'Cystic-Fibrosis.com',
  'Endometriosis.net',
  'EpilepsyDisease.com',
  'H-I-V.net',
  'Heart-Failure.net',
  'HepatitisC.net',
  'HSDisease.com',
  'InflammatoryBowelDisease.net',
  'IrritableBowelSyndrome.net',
  'LungCancer.net',
  'Lupus.net',
  'MacularDegeneration.net',
  'Mental-Health-Community.com',
  'Migraine.com',
  'MultipleSclerosis.net',
  'Myasthenia-Gravis.com',
  'NASHDisease.net',
  'Neuromyelitis-Optica.net',
  'Obesity.net',
  'Opioid-Use-Disorder.com',
  'ParkinsonsDisease.net',
  'PlaquePsoriasis.com',
  'ProstateCancer.net',
  'Psoriatic-Arthritis.com',
  'RareDisease.net',
  'RheumatoidArthritis.net',
  'Sickle-Cell.com',
  'SkinCancer.net',
  'Sleep-Disorders.net',
  'SocialHealthNetwork.com',
  'SpinalMuscularAtrophy.net',
  'ThyroidEyeDisease.net',
  'Type2Diabetes.com',
// JS array sort defaults to alphabetical ascending.
// Try to keep the above sorted for easy reference, too (f1 -> sort lines ascending in VS code)
].sort((a, b) => a.replace(/-/g, '').toLowerCase().localeCompare(b.replace(/-/g, '').toLowerCase()));
