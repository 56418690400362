<template>
  <transition
    name="accessibility-menu"
    appear
  >
    <div
      id="accessibility-menu"
      v-click-outside="onClickOutside"
      tabindex="0"
      class="accessibility-menu"
      :class="{
        'accessibility-menu--logged-out' : !userIsLoggedIn,
        'accessibility-menu--sub-button-shown' : subButtonShown
      }"
      aria-description="Accessibility tools, buttons to change the appearance of the website"
    >
      <ul class="accessibility-menu__options accessibility-menu__options--font-sizes">
        <accessibility-option
          name="Regular font size"
          :setting="{ 'fontSize': 'font-reg' }"
          aria-label="Standard Font Size"
        >A</accessibility-option>
        <accessibility-option
          name="Large font size"
          :setting="{ 'fontSize': 'font-lg' }"
          aria-label="Large Font Size"
        >A</accessibility-option>
        <accessibility-option
          name="Extra large font size"
          :setting="{ 'fontSize': 'font-xl' }"
          aria-label="Extra Large Font Size"
        >A</accessibility-option>
      </ul>
      <ul class="accessibility-menu__options accessibility-menu__options--contrast">
        <accessibility-option
          name="Regular contrast"
          :setting="{ 'contrast': 'contrast-reg' }"
          aria-label="Standard Contrast Style"
        >T</accessibility-option>
        <accessibility-option
          name="Low contrast"
          :setting="{ 'contrast': 'contrast-low' }"
          aria-label="Low Contrast Style"
        >T</accessibility-option>
        <accessibility-option
          name="Light contrast"
          :setting="{ 'contrast': 'contrast-light' }"
          aria-label="Bright High Contrast Style"
        >T</accessibility-option>
        <accessibility-option
          name="Dark contrast"
          :setting="{ 'contrast': 'contrast-dark' }"
          aria-label="Dark High Contrast Style"
        >T</accessibility-option>
      </ul>
      <button
        class="accessibility-menu__reset-button button"
        aria-label="Reset Accessibility Styles to Default"
        @click="resetAccessibility"
      >Reset</button>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import AccessibilityOption from '@/components/Accessibility/AccessibilityOption.vue';

export default {
  name: 'AccessibilityMenu',
  components: {
    AccessibilityOption,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    setFocus: {
      type: Boolean,
      default: false,
    },
    subButtonShown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    a11yMenuOpen() {
      return this.$store.state.accessibility.menuOpen;
    },
    ...mapGetters([
      'userIsLoggedIn',
    ]),
  },
  mounted() {
    if (this.setFocus) {
      this.$el.focus();
    }
  },
  methods: {
    resetAccessibility() {
      this.$store.dispatch('resetAccessibilitySettings');
    },
    closeA11yMenu() {
      this.$store.dispatch('setA11yMenuOpen', false);
    },
    onClickOutside(event) {
      const targetIsTrigger = event.target.classList.contains('header__button--accessibility');
      if (!this.a11yMenuOpen || targetIsTrigger) { return; }

      this.closeA11yMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_accessibility-menu';
</style>

<docs>

A menu displaying AccessibilityOptions, opens when the AccessibilityButton is pressed.
To see this component's example, click the example AccessibilityButton above.

</docs>
