/**
 * Nexus Auth service
 *
 * This service is used to communicate with the Nexus-api Auth controller
 * it is attached to the vue instance $auth which contains the API found here
 *
 * Calls to auth will always return a promise.
 *
 * Network errors are handled in the plugin. The component calling auth is
 * responsible for handling other errors as they will require different treatment
 * based on context.
 */
import axios from 'axios';
import { apiPath, host } from '@/utils/host-helpers';
import { loggerApi } from '@/plugins/logger';
import { prepRequestHeaders } from '@/utils/request-headers';

export function authFactory({ context, store }) {
  return {
    logger: loggerApi({ context }),
    endpoint: `${apiPath(context)}/api/auth`,
    requestOptions() {
      return {
        headers: prepRequestHeaders(context, store),
        withCredentials: true,
        params: context?.req?.query,
      };
    },
    /**
     *
     * @param { username: String, email: String, email_confirm: String } creds
     */
    register(creds) {
      return axios
        .post(`${this.endpoint}/register`, creds, this.requestOptions())
        .then((response) => {
          if (response.data.status === 'error') { return response.data; }

          store.dispatch('setCurrentUser', response.data.user);

          return response.data;
        })
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     *
     * @param { username: String, email: String } creds
     */
    upgradeLead(creds) {
      return axios
        .post(`${this.endpoint}/upgrade-lead`, creds, this.requestOptions())
        .then((response) => {
          if (response.data.status === 'error') { return response.data; }

          return response.data;
        })
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     * Login using email/password
     *
     * @param creds { email: String, password: String, remember_me: String }
     */
    logIn(creds) {
      const params = { initiator: store.getters.authInitiator, ...creds };
      return axios.post(`${this.endpoint}/identity/callback`, params, this.requestOptions())
        .then((response) => {
          if (response.data.status === 'error') { return response.data; }

          store.dispatch('setCurrentUser', response.data.user);

          return response.data;
        })
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     * Log out the current user
     */
    logOut() {
      return axios
        .get(`${this.endpoint}/log-out`, this.requestOptions())
        .then((response) => {
          if (response.data.status === 'error') {
            return response.data;
          }
          store.dispatch('clearCurrentUser');
          return response.data;
        })
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     * Reset a user's password using a reset_key issued via email
     *
     * @param email_or_username{ String }
     */
    requestPasswordReset(creds) {
      return axios
        .post(`${this.endpoint}/request-password-reset`, creds, this.requestOptions())
        .then((response) => response.data)
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     * Reset a user's password using a reset_key issued via email
     *
     * @param creds { email_or_username: String, password: String, reset_key: String }
     */
    resetPassword(creds) {
      return axios
        .post(`${this.endpoint}/reset-password`, creds, this.requestOptions())
        .then((response) => {
          if (response.data.status === 'error') { return response.data; }

          store.dispatch('setCurrentUser', response.data.user);
          return response.data;
        })
        .catch((error) => {
          this.displayErrorMessage();
          this.logger.error(error);
        });
    },
    /**
     * Check whether a user has a valid nexus session cookie
     *
     */
    checkSession() {
      if (
        process.browser
        // eslint-disable-next-line no-underscore-dangle
        || context?.req?.cookies?._nexus_key
        || context?.req?.query?.utm_confid
      ) {
        return axios
          .get(`${this.endpoint}/check-session`, this.requestOptions())
          .then((response) => response)
          .catch((error) => {
            this.displayErrorMessage();
            this.logger.error(error);
          });
      }

      return Promise.resolve({ headers: {}, data: { user: { sessionId: store.getters.pageViewId } } });
    },
    /*
    * Login via Facebook
    */
    facebookLogIn(callback) {
      this.popSocialLogin(`${this.endpoint}/facebook/login`, callback, 'facebook');
    },
    /**
     * Login via Google
     */
    googleLogIn(callback) {
      this.popSocialLogin(`${this.endpoint}/google/login`, callback, 'google');
    },
    popSocialLogin(url, callback, type) {
      const windowUrl = new URL(url);
      if (store.getters.authInitiator) {
        windowUrl.searchParams.append('initiator', store.getters.authInitiator);
      }
      // facebook browser cannot open popups (it navigates the current page)
      // this stores the current url so we can redirect in the callback
      localStorage.setItem('nexus.socialLoginRedirect', window.location);
      const popup = window.open(
        windowUrl,
        'popUpWindow',
        'height=600,width=600,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,status=yes',
      );
      if (popup) {
        const popupTick = window.setInterval(() => {
          if (popup.closed) {
            clearInterval(popupTick);
            callback(type);
          }
        }, 200);
      }
    },
    /**
     * Unlink a user's social login
     *
     * @param authAccountId String
     */
    unlinkSocialAccount(authAccountId) {
      return axios
        .post(`${this.endpoint}/unlink-social`, { auth_account_id: authAccountId }, this.requestOptions());
    },
    /**
     * Show toast if a network error occurs
     */
    displayErrorMessage() {
      store.dispatch('addToastNotification', {
        toastType: 'error',
        description: `An error occurred. Try again or reach out to contact@${host(context)}`,
      });
    },
  };
}

export function authInstaller(app, inject) {
  return new Promise((resolve) => {
    const authApi = authFactory(app);
    inject(app, '$auth', authApi);
    resolve();
  });
}
